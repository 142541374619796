import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderNavigationComponent } from './loader-navigation.component';

@NgModule({
  declarations: [LoaderNavigationComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatProgressSpinnerModule
  ],
  exports: [LoaderNavigationComponent]
})
export class LoaderNavigationModule { }
